import { createSlice } from '@reduxjs/toolkit'
import ROUTES from '../../routes/index'
import home_icon from '../../assets/images/Navigation_icons/home_icon.svg'
import projects_icon from '../../assets/images/Navigation_icons/projects_icon.svg'
import billing_icon from '../../assets/images/Navigation_icons/billing_icon.svg'
import settings_icon from '../../assets/images/Navigation_icons/settings.svg'

export interface NavigationState {
  navigation: any
  settingsModal: number
}

const initialState: NavigationState = {
  navigation: {
    items: [
      {
        id: 'projects-tools',
        title: '',
        type: 'group',
        icon: 'icon-ui',
        children: [
          {
            id: 'data-duration',
            title: 'Home',
            type: 'item',
            url: ROUTES.HOME_PAGE,
            icon: home_icon,
          },
          {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: ROUTES.PROJECTS,
            icon: projects_icon,
          },
          {
            id: 'billing',
            title: 'Billing',
            type: 'item',
            url: ROUTES.BILLING,
            icon: billing_icon,
          },
          {
            id: 'setting',
            title: 'Settings',
            type: 'item',
            url: ROUTES.SETTINGS,
            icon: settings_icon,
          },
        ],
      },
    ],
  },
  settingsModal: 0,
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setDefaultMenu: state => {
      state.navigation = initialState.navigation
    },
    setSettingsModal: (state, action) => {
      state.settingsModal = action.payload
    },
  },
})

export const { setDefaultMenu, setSettingsModal } = navigationSlice.actions

export default navigationSlice.reducer
