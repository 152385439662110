import { useState, useEffect } from 'react'
import { useFirebase } from '../firebase'
import { httpsCallable } from 'firebase/functions'

const useGetConfig = () => {
  const [time, setTime] = useState<any>()
  const { functions } = useFirebase()

  useEffect(() => {
    const fun = httpsCallable(functions, 'getServerTime')
    const data = fun().then(res => setTime(res.data))
  }, [])

  return { time }
}

export default useGetConfig
