import React, { Suspense } from 'react'
import Loader from '../../../components/Layout/Loader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import NotFound from '../../../components/NotFound'
import permissionSvg from '../../../assets/images/permission.svg'
import useProjectRole from '../../../hooks/useProjectRole'
import { useNavigate } from 'react-router-dom'
import { FREE_PLAN, getNumberOfProjectsAllowed, notify } from '../../../helpers'

const PrivateRouteElement = ({ route }: any) => {
  const { user, customer } = useSelector((state: RootState) => state.auth)
  const { userRole } = useProjectRole()

  const navigate = useNavigate()

  const paths = {
    viewer: ['/import', '/export', '/guidelines'],
  }

  const checkPath = () => {
    for (const path of paths.viewer) {
      if (route.path.includes(path) && userRole === 'viewer') return true
    }
    return false
  }

  const checkProjects = () => {
    if (route.path.includes('/projects/create/step-1')) {
      const {
        createdProjectsNum,
        subscriptionType,
        subscriptionStatus,
        isTrialUsed,
      } = customer
      if (subscriptionType === FREE_PLAN && isTrialUsed) {
        // free account
        // if (createdProjectsNum === 0) {
        //   return false
        // }
        return 'free'
      } else if (subscriptionType === FREE_PLAN && !isTrialUsed) {
        // free account
        // if (createdProjectsNum === 0) {
        //   return false
        // }
        return 'free-trial'
      }
      const numOfProjectsAllowed = getNumberOfProjectsAllowed(
        subscriptionType,
        subscriptionStatus
      )
      if (createdProjectsNum >= numOfProjectsAllowed) {
        return 'upgrade'
      }
      return ''
    }
  }

  if (checkPath()) {
    if (!user || userRole === null) return null
    return (
      <NotFound
        svg={permissionSvg}
        desc="You do not have permission to access this page"
        height={'85vh'}
      />
    )
  }

  if (checkProjects() === 'free') {
    notify('WARN', 'In order to create projects, you need to have plan')
    navigate('/settings', {
      state: { from: 'PaymentAndBilling' },
    })
  } else if (checkProjects() === 'free-trial') {
    notify(
      'WARN',
      'In order to create projects, you need to start the trial period.'
    )
    navigate('/')
  } else if (checkProjects() === 'upgrade') {
    notify('WARN', 'You have reached the maximum number of projects allowed')
    navigate('/settings', {
      state: { from: 'PaymentAndBilling' },
    })
  }

  return (
    <Suspense fallback={<Loader />}>
      <route.component />
    </Suspense>
  )
}

export default PrivateRouteElement
