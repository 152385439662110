import { noCase } from 'change-case'
import { useState, useRef, useEffect } from 'react'
import React from 'react'

import {
  Box,
  List,
  Badge,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemButton,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router'
import Iconify from './Iconify'
import MenuPopover from './MenuPopOver'
import { fToNow } from './formatTime'

import { useSelector } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useFirebase } from '../../../../../firebase'
import { RootState } from '../../../../../store'
import { onSnapshot } from 'firebase/firestore'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // xxl: 1700,
      // xxxl: 1800,
    },
  },
})

export default function Notifications({ customerID }: { customerID: string }) {
  const [notifications, setNotifications] = useState<any[]>([])
  const [oldNotifications, setOldNotifications] = useState<any[]>([])
  const [newNotifications, setNewNotifications] = useState<any[]>([])
  const [totalUnreadCount, setTotalUnreadCount] = useState<any>(0)
  const { api } = useFirebase()
  const { user } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  //  Modal handlers
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(null)
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  //  Notifications handlers
  const handleMarkAllAsRead = async () => {
    for (const notif of notifications) {
      await api?.updateCustomerIsUnread(notif.notifID)
    }
  }

  const handleIsRead = async (notifID: any) => {
    await api?.updateCustomerIsUnread(notifID)
  }

  //  useEffects
  useEffect(() => {
    if (!user) return
    const sortedNotifications = notifications.sort(
      (a, b) => b.timestamp - a.timestamp
    )
    const oldNotifs = sortedNotifications.filter(
      notif => notif.timestamp < Date.now() - 86400000
    )
    const newNotifs = sortedNotifications.filter(
      notif => notif.timestamp > Date.now() - 86400000
    )
    setOldNotifications(oldNotifs)
    setNewNotifications(newNotifs)

    const unreadNotifications = notifications.filter(
      notif => notif.receivers[user.uid] === true
    )
    setTotalUnreadCount(unreadNotifications.length)
    // console.log(sortedNotifications);
  }, [notifications, user])

  useEffect(() => {
    if (!user) return
    const unsub = onSnapshot(
      api?.listenToNotifications(),
      (notifsSnap: any) => {
        const notifs = notifsSnap.docs.map((doc: any) => doc.data())
        setNotifications(notifs)
      }
    )
    return () => {
      unsub()
    }
  }, [api, user])

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ '&:focus': { outline: 'none' } }}
      >
        <Badge
          sx={{
            '& .MuiBadge-badge': { fontSize: 9, height: 15, minWidth: 15 },
          }}
          badgeContent={totalUnreadCount}
          color="error"
        >
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 330,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          height: '60%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{ fontFamily: 'facto, sans-serif' }}
              variant="subtitle1"
            >
              Notifications
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', fontFamily: 'facto, sans-serif' }}
            >
              You have {totalUnreadCount} unread messages
            </Typography>
          </Box>

          {totalUnreadCount > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton
                color="primary"
                onClick={handleMarkAllAsRead}
                sx={{ '&:focus': { outline: 'none' } }}
              >
                <Iconify icon="eva:done-all-fill" width={25} height={25} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              flexGrow: 1,
              height: {
                xs: '65%',
                sm: '67%',
                md: '67%',
                lg: '73%',
                xl: '76%',
                xxl: '78%',
                xxxl: '80%',
              },
              overflow: 'auto',
            }}
          >
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{
                    py: 1,
                    px: 2.5,
                    typography: 'overline',
                    fontFamily: 'facto, sans-serif',
                  }}
                >
                  Today
                </ListSubheader>
              }
            >
              {newNotifications.length > 0 ? (
                newNotifications.map(notification => (
                  <NotificationItem
                    key={notification.notifID}
                    notification={notification}
                    customerID={customerID}
                    handleIsRead={handleIsRead}
                    handleClose={handleClose}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 2,
                  }}
                >
                  <Typography
                    sx={{ fontFamily: 'facto, sans-serif' }}
                    variant="subtitle2"
                  >
                    No new notifications
                  </Typography>
                </Box>
              )}
            </List>

            {oldNotifications.length > 0 ? (
              <>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <List
                  disablePadding
                  subheader={
                    <ListSubheader
                      disableSticky
                      sx={{
                        py: 1,
                        px: 2.5,
                        typography: 'overline',
                        fontFamily: 'facto, sans-serif',
                      }}
                    >
                      Before that
                    </ListSubheader>
                  }
                >
                  {oldNotifications.map(notification => (
                    <NotificationItem
                      key={notification.notifID}
                      notification={notification}
                      customerID={customerID}
                      handleIsRead={handleIsRead}
                      handleClose={handleClose}
                    />
                  ))}
                </List>
              </>
            ) : (
              <></>
            )}
          </Box>
        </ThemeProvider>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Button
          fullWidth
          disableRipple
          sx={{ fontFamily: 'facto, sans-serif', marginTop: '0px' }}
          onClick={() => {
            navigate('/settings', { state: { from: 'notification' } })
            setOpen(null)
          }}
        >
          Notification Settings
        </Button>
      </MenuPopover>
    </>
  )
}

// ----------------------------------------------------------------------

function NotificationItem({
  notification,
  handleClose,
  customerID,
  handleIsRead,
}: {
  notification?: any
  handleClose?: any
  customerID?: string | any
  handleIsRead?: any
}) {
  const { title } = renderContent(notification)
  const navigate = useNavigate()

  return (
    <>
      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.receivers[customerID] && {
            bgcolor: 'action.selected',
          }),
        }}
        onClick={() => {
          if (
            notification.type === 'issueCreated' ||
            notification.type === 'issueAnswered'
          ) {
            navigate(notification.onClick, {
              state: { from: 'issueCreated', metadata: notification?.metadata },
            })
          }
          handleIsRead(notification.notifID)
          handleClose()
        }}
      >
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
                fontFamily: 'facto, sans-serif',
              }}
            >
              <Iconify
                icon="eva:clock-outline"
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {fToNow(notification.timestamp)}
            </Typography>
          }
        />
      </ListItemButton>
    </>
  )
}

// ----------------------------------------------------------------------

function renderContent(notification: any) {
  const title = (
    <Typography sx={{ fontFamily: 'facto, sans-serif' }} variant="subtitle2">
      {notification.title}
      <br />
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary', fontFamily: 'facto, sans-serif' }}
      >
        {noCase(notification.message)}
      </Typography>
    </Typography>
  )
  return {
    icon: notification.icon,
    title,
  }
}
