import { toast } from 'react-toastify'
// import { setProjectMenu } from "../store/navigation/action";
import * as XLSX from 'xlsx/xlsx.mjs'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
// import {
//   asyncActionFinish,
//   asyncActionStart,
// } from "../store/async/asyncReducer";
import timezones from './timezones'
import { asyncActionFinish, asyncActionStart } from '../store/async'
import colors from './colors'
import { v4 as uuidv4 } from 'uuid'

export const convertPointsToArray = (data) => {
  data.forEach((anno) => {
    anno.regions.forEach((reg) => {
      if (reg.points) {
        reg.points = [...Object.values(reg.points)]
      }
    })
  })
}

export const convertPointsToObject = (newRegions) => {
  newRegions.forEach((region) => {
    if (region.points) {
      region.points = convertArrayToObject(region.points)
    }
  })

  return newRegions
}


export const truncateFileName = (fileName, maxLength) => {
  const extension = fileName.slice(fileName.lastIndexOf('.'))
  const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'))

  if (nameWithoutExtension.length > maxLength) {
    return `${nameWithoutExtension.slice(0, maxLength)}...${extension}`
  }

  return fileName
}

export const getDefaultCountry = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (timezone === '' || !timezone) {
    return null
  }

  const timezoneInfo = timezones[timezone]
  if (!timezoneInfo) {
    return null
  }

  const country = timezoneInfo.c?.[0]
  if (!country) {
    return null
  }

  return country
}

export const handleDateFromDoc = (data) => {
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop].seconds && data[prop].nanoseconds) {
        data[prop] = data[prop].toDate()
      } else if (typeof data[prop] === 'object') {
        let data2 = data[prop]
        for (const prop2 in data2) {
          if (data2[prop2].seconds && data2[prop2].nanoseconds) {
            data2[prop2] = data2[prop2].toDate()
          }
        }
      }
    }
  }

  return data
}

export const dataFromSnapshot = (snapshot, idName = 'projectID') => {
  if (!snapshot.exists) return undefined
  const data = snapshot.data()
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop].seconds && data[prop].nanoseconds) {
        data[prop] = data[prop].toDate()
      } else if (typeof data[prop] === 'object') {
        let data2 = data[prop]
        for (const prop2 in data2) {
          if (data2[prop2].seconds && data2[prop2].nanoseconds) {
            data2[prop2] = data2[prop2].toDate()
          }
        }
      }
    }
  }

  return {
    ...data,
    [idName]: snapshot.id,
  }
}

export const handleGetProjectByName = (docs, dispatch) => {
  let resultedDocs = []
  docs.forEach((doc) => {
    resultedDocs.push({ ...handleDateFromDoc(doc.data()), projectID: doc.id })
  })

  // dispatch(setProjectMenu(resultedDocs[0]));
  return resultedDocs[0]
}

export const getProgetProgress = (projectData) => {
  const { totalData, completedData } = projectData?.projectTrack ?? {}
  const denemenator = totalData === 0 ? 1 : totalData
  const numenerator = completedData
  let value = Math.floor((numenerator / denemenator) * 100)
  value = value > 100 ? 100 : value
  return value
}

export const objectToArray = (e) => {
  if (!e) return []
  const errors = Object.entries(e).map((e) => {
    return { control: e[0], error: e[1] }
  })

  return errors
}

export const notify = (status, value) => {
  switch (status) {
    case 'SUCCESS':
      toast.success(value)
      break

    case 'FAIL':
      toast.error(value)
      break

    case 'WARN':
      toast.warn(value)
      break

    case 'INFO':
      toast.info(value)
      break
    default:
      break
  }
}

export const getFilesNames = (files) => {
  let filesNames = files.map((file) => file.name)
  return filesNames
}

export const convertExcelToJson = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)

    fileReader.onload = (e) => {
      const bufferArray = e.target.result
      const wb = XLSX.read(bufferArray, { type: 'buffer' })
      const wname = wb.SheetNames[0]
      const ws = wb.Sheets[wname]
      const data = XLSX.utils.sheet_to_json(ws)
      resolve(data)
    }

    fileReader.onerror = (e) => {
      reject(e)
    }
  })
}

export const capitilizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const createObjectUrl = (file) => {
  if (!file) return null
  return URL.createObjectURL(file)
}

export const getImageSizes = (image, handler) => {
  let img = new Image()
  img.onload = function () {
    handler({ width: img.width, height: img.height })
  }
  img.src = createObjectUrl(image)
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const isArraysEqual = (array1, array2) => {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  )
}

export const convertArrayToObject = (arr) => {
  return Object.assign({}, [...arr]) // {0:"a", 1:"b", 2:"c"}
}

export const checkGuidelinesSamples = (guidelinesTrack) => {
  return guidelinesTrack && Object.values(guidelinesTrack).every((x) => x)
}

export const getGuidelinesUnsampledClasses = (guidelinesTrack) => {
  let result = []
  Object.keys(guidelinesTrack).forEach((key) => {
    if (!guidelinesTrack[key]) result.push(key)
  })

  return result
}

export const scrollTo = (id, more) => {
  let scrollDiv = document.getElementById(id)?.offsetTop + more
  if (scrollDiv) window.scrollTo({ top: scrollDiv, behavior: 'smooth' })
}

export const getCloudFunction = (functions, functionName) => {
  return httpsCallable(functions, functionName)
}

export const getProjectExpectedDelivery = (serverTime, totalData) => {
  return (
    serverTime +
    ((totalData * 20) / 5000) * 3 * 24 * 60 * 60 * 1000 +
    1000 * 60 * 60 * 24 * 3
  ) // en az 3gün
}

export const downloadZip = (imgName, type, content) => {
  let zip = new JSZip()
  zip.file(`annotation.${type}`, content)
  // let img = zip.folder('images');
  // img.file('smile.gif', imgData, { base64: true });
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    // see FileSaver.js
    saveAs(content, `${imgName}-${type}.zip`)
  })
}

export const downloadMultipeZip = (payloads) => {
  let zip = new JSZip()

  payloads.forEach((payload) => {
    if (typeof payload.content === 'string')
      zip = zip.file(payload.name, payload.content)
    else {
      let folder = zip.folder(payload.name)
      folder.file(payload.content.name, payload.content.content)
    }
  })
  // let img = zip.folder('images');
  // img.file('smile.gif', imgData, { base64: true });
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    // see FileSaver.js
    saveAs(content, `test.zip`)
  })
}

export const downloadFileByUrl = (url, fileName) => {
  let link = document.createElement('a')
  link.setAttribute('download', fileName)
  link.setAttribute('target', '_blank')
  link.setAttribute('href', url)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const navigateToFileByUrl = (url) => {
  let link = document.createElement('a')
  link.setAttribute('target', '_blank')
  link.setAttribute('href', url)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getAnnotationType = (projectAnnoType) => {
  const tools = []
  switch (projectAnnoType) {
    case 'cuboid':
      tools.push(...['select', 'create-cuboid'])
      break
    case 'polygon':
      tools.push(...['select', 'create-polygon', 'create-issue'])
      break
    case 'boundingBox':
      tools.push(...['select', 'create-box', 'create-issue'])
      break
    case 'multiClassification':
      tools.push(...['select', 'create-point', 'create-issue'])
      break
    case 'keypoint':
      tools.push(...['select', 'create-point', 'create-issue'])
      break
    case 'polyline':
      tools.push(...['select', 'create-polyline', 'create-issue'])
      break
    case 'polyline-bs':
      tools.push(
        ...[
          'create-box',
          'create-point',
          'create-polygon',
          'create-polyline-bs',
          'open-image-tags',
          'create-issue',
        ]
      )
      break
    default:
      tools.push(
        ...[
          'create-polygon',
          'create-point',
          'create-box',
          'select',
          'create-issue',
        ]
      )
      break
  }
  // guideline check location remove create-issue
  if (window.location.pathname.includes('guideline')) {
    const index = tools.indexOf('create-issue')
    if (index > -1) {
      tools.splice(index, 1)
    }
  }
  return tools
}

export const getFileExtensionFromName = (fileName) => {
  if (!fileName) return ''
  const dotIndex = fileName.lastIndexOf('.')
  const fileExtension = fileName.substring(dotIndex + 1)
  return fileExtension
}

export const numberToFix = (value, numPoints) => {
  return +Number(value).toFixed(numPoints)
}

export const fakeAsyncAction = async (timeout, dispatch) => {
  dispatch(asyncActionStart())
  await new Promise((resolve) => setTimeout(resolve, timeout))
  dispatch(asyncActionFinish())
}

export const getSubString = (text, max) =>
  text?.length > max ? text?.slice(0, max) + '...' : text

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getNumberOfProjectsAllowed = (
  subscriptionType,
  subscriptionStatus
) => {
  if (subscriptionStatus === 'trialing') return 10
  else if (subscriptionType === 'Basic') return 1
  else if (subscriptionType === 'Professional') return 100
  else if (subscriptionType === 'Enterprise') return 15
  else return 0
}

export const getInitialLabelStudioAnnot = (
  guidelines,
  guidelineIndex,
  annotationType
) => {
  switch (annotationType) {
    case 'textClassification':
      return {
        id: guidelines[guidelineIndex].id,
        pk: null,
        selected: true,
        type: 'annotation',
        acceptedState: null,
        createdDate: '2023-05-16T12:08:07.834',
        createdAgo: null,
        createdBy: 'Admin',
        user: null,
        parent_prediction: null,
        parent_annotation: null,
        last_annotation_history: null,
        loadedDate: 1684228087834,
        leadTime: null,
        userGenerate: false,
        update: false,
        sentUserGenerate: false,
        localUpdate: false,
        ground_truth: false,
        skipped: false,
        history: {
          undoIdx: 1,
          targetPath: '../areas',
          skipNextUndoState: false,
          createdIdx: 0,
        },
        dragMode: false,
        editable: true,
        relationMode: false,
        relationStore: { _relations: [], showConnections: true },
        areas:
          guidelines[guidelineIndex].label.length > 0
            ? {
                HHFTvhVVfr: {
                  pid: '9FonMZHcrt',
                  score: null,
                  readonly: false,
                  hidden: false,
                  parentID: null,
                  fromSuggestion: false,
                  dynamic: false,
                  origin: 'manual',
                  id: 'HHFTvhVVfr',
                  results: [
                    {
                      id: 'dKFv4Ony0z',
                      score: null,
                      from_name: 'sentiment',
                      to_name: 'text',
                      type: 'choices',
                      value: {
                        choices: [guidelines[guidelineIndex].label[0]],
                      },
                    },
                  ],
                  object: 'text',
                  classification: true,
                },
              }
            : {},
        suggestions: {},
        regionStore: {
          sort: 'date',
          sortOrder: 'desc',
          group: 'type',
          view: 'regions',
          selection: { selected: {} },
        },
      }
    case 'textPromptGeneration':
      return {
        id: guidelines[guidelineIndex].id,
        pk: null,
        selected: true,
        type: 'annotation',
        acceptedState: null,
        createdDate: '2023-05-17T13:43:41.004',
        createdAgo: null,
        createdBy: 'Admin',
        user: null,
        parent_prediction: null,
        parent_annotation: null,
        last_annotation_history: null,
        loadedDate: 1684320221004,
        leadTime: null,
        userGenerate: false,
        update: false,
        sentUserGenerate: false,
        localUpdate: false,
        ground_truth: false,
        skipped: false,
        history: {
          undoIdx: 1,
          targetPath: '../areas',
          skipNextUndoState: false,
          createdIdx: 0,
        },
        dragMode: false,
        editable: true,
        relationMode: false,
        relationStore: { _relations: [], showConnections: true },
        areas: guidelines[guidelineIndex].text
          ? {
              Dh1SS5xWP3: {
                pid: 'M9iZjIwPvN',
                score: null,
                readonly: false,
                hidden: false,
                parentID: null,
                fromSuggestion: false,
                dynamic: false,
                origin: 'manual',
                id: 'Dh1SS5xWP3',
                results: [
                  {
                    id: '-mxaQ-3AMU',
                    score: null,
                    from_name: 'handwritten',
                    to_name: 'handwritten',
                    type: 'choices',
                    value: {
                      choices:
                        guidelines[guidelineIndex].label.length > 0
                          ? [guidelines[guidelineIndex].label[0]]
                          : [],
                    },
                  },
                ],
                object: 'handwritten',
                classification: true,
              },
              zsQxA7QTXI: {
                pid: '5U2OI8e0Gb',
                score: null,
                readonly: false,
                hidden: false,
                parentID: null,
                fromSuggestion: false,
                dynamic: false,
                origin: 'manual',
                id: 'zsQxA7QTXI',
                results: [
                  {
                    id: 'KEdO0Zp-Kj',
                    score: null,
                    from_name: 'prompt',
                    to_name: 'prompt',
                    type: 'textarea',
                    value: { text: [guidelines[guidelineIndex].text] },
                  },
                ],
                object: 'prompt',
                classification: true,
              },
            }
          : {},

        suggestions: {},
        regionStore: {
          sort: 'date',
          sortOrder: 'desc',
          group: 'type',
          view: 'regions',
          selection: { selected: {} },
        },
      }
    case 'NER':
      const areas = {}
      guidelines[guidelineIndex]?.areas?.forEach((a, i) => {
        areas[a.id] = {
          pid: 'OS-YO3M3dO',
          score: null,
          readonly: false,
          hidden: false,
          parentID: null,
          fromSuggestion: false,
          dynamic: false,
          origin: 'manual',
          id: a.id,
          results: [
            {
              id: 'i6V4EXblhv',
              score: null,
              from_name: 'label',
              to_name: 'text',
              type: 'labels',
              value: {
                labels: [a.label],
              },
            },
          ],
          meta: {},
          normInput: null,
          type: 'richtextregion',
          object: 'text',
          startOffset: a.start,
          endOffset: a.end,
          start: `/text()[${i + 1}]`,
          end: `/text()[${i + 1}]`,
          text: a.text,
          isText: true,
          globalOffsets: {
            start: a.start,
            end: a.end,
          },
        }
      })
      const relations = guidelines[guidelineIndex]?.relations?.map((rl) => ({
        id: rl.id,
        node1: rl.node1,
        node2: rl.node2,
        direction: rl.direction,
        relations: null,
        showMeta: false,
      }))
      return {
        id: '87MaX',
        pk: null,
        selected: true,
        type: 'annotation',
        acceptedState: null,
        createdDate: '2023-12-28T13:25:51.751',
        createdAgo: null,
        createdBy: 'Admin',
        user: null,
        parent_prediction: null,
        parent_annotation: null,
        last_annotation_history: null,
        loadedDate: 1703759151751,
        leadTime: null,
        userGenerate: false,
        update: false,
        sentUserGenerate: false,
        localUpdate: false,
        ground_truth: false,
        skipped: false,
        history: {
          undoIdx: 3,
          targetPath: '../areas',
          skipNextUndoState: false,
          createdIdx: 0,
        },
        dragMode: false,
        editable: true,
        relationMode: false,
        relationStore: {
          _relations: relations,
          showConnections: true,
        },
        areas,
        suggestions: {},
        regionStore: {
          sort: 'date',
          sortOrder: 'desc',
          group: 'type',
          view: 'regions',
          selection: {
            selected: {},
          },
        },
      }
    default:
      break
  }
}

export const getLabelStudioConfig = (projectData) => {
  const attributes = projectData.projectRequirments.attributes
  const annotationType = projectData.projectRequirments.annotationType
  let choices = ''
  let labels = ''

  switch (annotationType) {
    case 'textClassification':
      attributes.forEach((attr) => {
        choices += `<Choice value="${attr}"/>\n`
      })
      return `<View>
                <Text name="text" value="$text"/>
                <Choices name="sentiment" toName="text" choice="single" required="true" requiredMessage="You must specify your choice">
                  ${choices}
                </Choices>
              </View>`
    case 'textPromptGeneration':
      attributes.forEach((attr) => {
        choices += `<Choice value="${attr}"/>\n`
      })
      return `<View>
                <Choices name="handwritten" toName="handwritten" choice="single-radio" showInLine="true" required="true" requiredMessage="You must specify your choice">
                  ${choices}
                </Choices>
                <TextArea name="prompt" toName="prompt" maxSubmissions="1" showSubmitButton="true" placeholder="Enter your prompt..." value="" required="true" requiredMessage="You must specify your prompt"/>
              </View>`
    case 'NER':
      attributes.forEach((attr, i) => {
        labels += `<Label value="${attr}" background="${colors[i]}"/>\n`
      })
      return `<View>
                <Labels name="label" toName="text">
                  ${labels}
                </Labels>
    
                <Text name="text" value="$text"/>
            </View>`
    default:
      break
  }
}

export const dateToGoodFormat = (date) => {
  if (!date) {
    return '...'
  }
  const inputDate = date

  // Giriş tarihini JavaScript Date objesine dönüştürme
  const dateObj = new Date(inputDate)

  // Tarih bilgilerini almak
  const year = dateObj.getFullYear()
  const month = dateObj.toLocaleString('default', { month: 'long' })
  const day = dateObj.getDate()

  // Günün sıra numarasına göre ek getirmek (1st, 2nd, 3rd, 4th, ...)
  let daySuffix = 'th'
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = 'st'
  } else if (day === 2 || day === 22) {
    daySuffix = 'nd'
  } else if (day === 3 || day === 23) {
    daySuffix = 'rd'
  }

  // İstenen formatı oluşturma
  return `${month} ${day}${daySuffix}, ${year}`
}

export const fillDateArray = (startDate, dates, chartData) => {
  const started = new Date(startDate)
  const maxDate = new Date(Math.max(...dates.map((date) => new Date(date))))
  const ended = new Date(maxDate.toISOString().slice(0, 10))

  const labels = []
  const data = []

  let currentDate = new Date(started)

  while (currentDate <= ended) {
    const currentDateStr = currentDate.toISOString().split('T')[0]

    if (chartData?.labels?.includes(currentDateStr)) {
      const index = chartData?.labels?.indexOf(currentDateStr)
      labels.push(currentDateStr)
      data.push(chartData?.data[index])
    } else {
      labels.push(currentDateStr)
      data.push('0')
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }
  labels.push(currentDate.toISOString().split('T')[0])
  data.push('0')
  return {
    labels: labels,
    data: data,
  }
}
export const FREE_PLAN = 'free'
export const BASIC_PLAN = 'Basic'
export const PROFESSIONAL_PLAN = 'Professional'
export const ENTERPRISE_PLAN = 'Enterprise'
export const isBasicPlan = (subscriptionType, subscriptionStatus) => {
  if (subscriptionStatus === 'trialing') return false
  return [FREE_PLAN, BASIC_PLAN].includes(subscriptionType?.toLowerCase())
}

export const isGuidelined = (value) => {
  if (value === 'validation') {
    return true
  } else if (value === 'validation') {
    return false
  }
  return true
}

export const camelToFlat = (camel) => {
  if (!camel) return 'annotation'
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')

  let flat = ''

  camelCase.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + ' '
  })
  // remove last space
  flat = flat.slice(0, -1)
  return flat
}

export const removeSpecialChars = (str) => {
  if (!str) return ''
  return str
    .replace(/\//g, '')
    .replace(/\*/g, '')
    .replace(/%/g, '')
    .replace(/#/g, '')
    .replace(/@/g, '')
    .replace(/&/g, '')
    .replace(/!/g, '')
    .replace(/=/g, '')
    .replace(/\+/g, '')
}

export const urlToFile = async (url) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const pdfName = decodeURIComponent(url).split('.pdf')[0].split('/').at(-1)
      const file = new File([blob], pdfName + '.pdf', {
        type: 'application/pdf',
      })
      return file
    })
}
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const getRandomId = () => uuidv4()
