import React from 'react'
import SuccessSignup from '../../pages/Authentication/SuccessSignUp'
import ROUTES from '../index'

const Signup = React.lazy(() => import('../../pages/Authentication/SignUp'))
const Signin = React.lazy(() => import('../../pages/Authentication/SignIn'))
const EmailVerification = React.lazy(() =>
  import('../../pages/Authentication/EmailVerification')
)
const ResetPassword = React.lazy(() =>
  import('../../pages/Authentication/ResetPassword')
)
const ProjectInvite = React.lazy(() => import('../../pages/ProjectInvite'))
const Emailhandler = React.lazy(() =>
  import('../../pages/Authentication/CustomEmailHandlers')
)

const publicRoutes = [
  { path: ROUTES.SIGN_UP, name: 'Signup', component: Signup },
  { path: ROUTES.SIGN_IN, name: 'Signin', component: Signin },
  {
    path: ROUTES.EMAIL_VERIFICATION,
    name: 'EmailVerification',
    component: EmailVerification,
  },
  {
    path: ROUTES.SUCCESS_SIGNUP,
    name: 'SuccessSignup',
    component: SuccessSignup,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    name: 'ResetPassword',
    component: ResetPassword,
  },
  { path: ROUTES.INVITE_PROJECT, name: 'Invite', component: ProjectInvite },
  { path: ROUTES.EMAIL_HANDLER, name: 'emailHandler', component: Emailhandler },
]

export default publicRoutes
