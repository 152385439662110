import { createSlice } from '@reduxjs/toolkit';

export interface LocalUploadState {
  modalImagesLocal: any;
}

const initialState: LocalUploadState = {
  modalImagesLocal: [],
};

export const localUploadSlice = createSlice({
  name: 'localUpload',
  initialState,
  reducers: {
    addModalImageLocal: (state, action) => {
      state.modalImagesLocal = [...state.modalImagesLocal, action.payload];
    },
    removeModalImageLocal: (state, action) => {
      state.modalImagesLocal = [
        ...state.modalImagesLocal.filter(
          (image: any) => image.name !== action.payload.name
        ),
      ];
    },
    clearModalImagesLocal: (state) => {
      state.modalImagesLocal = [];
    },
    increaseImageProgressLocal: (state, action) => {
      let index = state.modalImagesLocal.findIndex(
        (image: any) => image.name === action.payload.name
      );
      let images = [...state.modalImagesLocal];
      images[index].progress = action.payload.progress;
      state.modalImagesLocal = [...images];
    },
  },
});

export const {
  addModalImageLocal,
  removeModalImageLocal,
  clearModalImagesLocal,
  increaseImageProgressLocal,
} = localUploadSlice.actions;

export default localUploadSlice.reducer;
