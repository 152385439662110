import React, { useEffect, useState } from 'react'
import { RootState } from '../../../../store'
import { useSelector } from 'react-redux'
import styles from './navbarLeft.module.scss'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ButtonBase, Menu, MenuItem } from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'
import { getSubString, isGuidelined } from '../../../../helpers'
import CooneLogo from '../../../../assets/images/logo.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import useProjectData from '../../../../hooks/useProjectData'

type Params = {
  isWelcomeMessageShown: any
  isProjectNavigationShown: any
  isSettingsNavigationShown: any
  isDocsNavigationShown: any
  getProjectName: any
  isToolFullScreen: any
}

const NavLeft = ({
  isWelcomeMessageShown,
  isProjectNavigationShown,
  isSettingsNavigationShown,
  isDocsNavigationShown,
  getProjectName,
  isToolFullScreen,
}: Params) => {
  const customer = useSelector((state: RootState) => state.auth.customer)
  const projects = useSelector((state: RootState) => state.projects.projects)
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const projectName = getProjectName()

  const [anchorEl, setAnchorEl] = useState<any>()
  const open = Boolean(anchorEl)

  const { projectData } = useProjectData()

  const getNavigationItems = () => {
    const splittedPath = path.split('/')
    if (splittedPath.length >= 3) {
      const projectName = splittedPath[2]
      if (projectData?.projectRequirments.isInternal) {
        const items = [
          {
            id: 'tracking',
            title: 'Tracking',
            url: `/projects/${projectName}/tracking`,
            exact: true,
          },
          {
            id: 'data-management',
            title: 'Data Managements',
            url: `/projects/${projectName}/dataManagements`,
            exact: false,
          },
          {
            id: 'analysis',
            title: 'Analysis',
            url: `/projects/${projectName}/analysis`,
            exact: true,
          },
          {
            id: 'review',
            title: 'Review',
            url: `/projects/${projectName}/review`,
            exact: true,
          },

          {
            id: 'task',
            title: 'Task',
            url: `/projects/${projectName}/task`,
            exact: true,
          },
        ]
        return items
      }
      if (projectData?.projectRequirments.dataType === 'text') {
        const items = [
          {
            id: 'tracking',
            title: 'Tracking',
            url: `/projects/${projectName}/tracking`,
            exact: true,
          },
          {
            id: 'data-management',
            title: 'Data Managements',
            url: `/projects/${projectName}/dataManagements`,
            exact: false,
          },
          {
            id: 'review',
            title: 'Review',
            url: `/projects/${projectName}/review`,
            exact: true,
          },
          // {
          //   id: 'guidelines',
          //   title: 'Guideline',
          //   url: `/projects/${projectName}/guidelines`,
          //   exact: true,
          // },
        ]
        return items
      } else {
        const items = [
          {
            id: 'tracking',
            title: 'Tracking',
            url: `/projects/${projectName}/tracking`,
            exact: true,
          },
          {
            id: 'data-management',
            title: 'Data Managements',
            url: `/projects/${projectName}/dataManagements`,
            exact: false,
          },
          {
            id: 'analysis',
            title: 'Analysis',
            url: `/projects/${projectName}/analysis`,
            exact: true,
          },
          {
            id: 'review',
            title: 'Review',
            url: `/projects/${projectName}/review`,
            exact: true,
          },
          {
            id: 'guidelines',
            title: 'Guideline',
            url: `/projects/${projectName}/guidelines`,
            exact: true,
            isDisabled: false,
          },
        ]
        return items
      }
    }
  }

  return (
    <div className={styles.navbarLeft}>
      {isWelcomeMessageShown() && (
        <div className={styles.WelcomeMessage}>
          <span className={styles.title}>
            Hello {customer?.profile?.firstName}
          </span>
          <span className={styles.desc}>Here you can see your projects.</span>
        </div>
      )}
      {isProjectNavigationShown() && (
        <div className={styles.ProjectNav}>
          <>
            {getProjectName() ? (
              <div className={styles.ProjectsMenu}>
                <ButtonBase
                  className={styles.ProjectsButton}
                  title={getProjectName()}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <AppsIcon />
                  <span>{getSubString(getProjectName(), 12)}</span>
                </ButtonBase>
                <Menu
                  sx={{
                    '& .MuiPaper-root': {
                      minWidth: 156,
                    },
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  {projects.map((proj: any) => (
                    <MenuItem
                      key={proj.projectID}
                      title={proj.projectName}
                      onClick={() => {
                        setAnchorEl(null)
                        navigate(`/projects/${proj.projectName}/tracking`)
                      }}
                    >
                      {getSubString(proj.projectName, 19)}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : null}
            {getNavigationItems()?.map(
              (item: any) =>
                !item?.isDisabled && (
                  <NavLink
                    to={item.url}
                    key={item.id}
                    className={({ isActive, isPending }) =>
                      isPending
                        ? styles.NavItem
                        : isActive
                        ? styles.ActiveNavItem
                        : styles.NavItem
                    }
                  >
                    {item.title}
                  </NavLink>
                )
            )}
          </>
        </div>
      )}

      {isToolFullScreen() && (
        <div className={styles.SettingsNav}>
          <Link to="/" className={styles.LogoLink}>
            <img alt="logo" className={styles.Logo} src={CooneLogo} />
          </Link>
          <Link
            to={`/projects/${projectName}/tracking`}
            className={styles.HomeButton}
          >
            <PlayArrowIcon
              fontSize="large"
              sx={{
                color: 'black',
                transform: 'rotate(180deg)',
                marginLeft: 1.5,
              }}
            />
            <span>Back</span>
          </Link>
        </div>
      )}
      {isDocsNavigationShown() && (
        <div className={styles.SettingsNav}>
          <Link to="/" className={styles.LogoLink}>
            <img alt="logo" className={styles.Logo} src={CooneLogo} />
          </Link>
          <Link to="/" className={styles.HomeButton}>
            <PlayArrowIcon
              fontSize="large"
              sx={{
                color: 'black',
                transform: 'rotate(180deg)',
                marginLeft: 1.5,
              }}
            />
            <span>Docs</span>
          </Link>
        </div>
      )}
    </div>
  )
}

export default NavLeft
