import React from 'react'
import ROUTES from '../index'

const HomePage = React.lazy(() => import('../../pages/Dashboard'))

const Projects = React.lazy(() => import('../../pages/Projects'))
const ProjectGuidelines = React.lazy(
  () => import('../../pages/ProjectGuidelines')
)
const ProjectTracking = React.lazy(() => import('../../pages/ProjectTracking'))
const ProjectDataManagement = React.lazy(
  () => import('../../pages/ProjectDataManagement')
)
const ProjectExport = React.lazy(
  () => import('../../pages/ProjectDataManagement/Export')
)
const ProjectImport = React.lazy(
  () => import('../../pages/ProjectDataManagement/Import')
)
const ProjectReview = React.lazy(() => import('../../pages/ProjectReview'))
const ProjectAnalysis = React.lazy(() => import('../../pages/ProjectAnalysis'))
const ProjectTask = React.lazy(() => import('../../pages/ProjectTask'))

const CreateProjectStep0 = React.lazy(
  () => import('../../pages/ProjectCreation/Step0')
)

const CreateProjectStep1 = React.lazy(
  () => import('../../pages/ProjectCreation/Step1')
)

const CreateProjectStep2 = React.lazy(
  () => import('../../pages/ProjectCreation/Step2')
)
const CreateProjectStep3 = React.lazy(
  () => import('../../pages/ProjectCreation/Step3')
)
const CreateProjectStep4 = React.lazy(
  () => import('../../pages/ProjectCreation/Step4')
)

const CreateProjectStep5 = React.lazy(
  () => import('../../pages/ProjectCreation/Step5')
)

const Settings = React.lazy(() => import('../../pages/Settings'))

const Billing = React.lazy(() => import('../../pages/Billing'))
const Docs = React.lazy(() => import('../../pages/Docs'))

const privateRoutes = [
  { path: ROUTES.HOME_PAGE, name: 'Default', component: HomePage },
  { path: ROUTES.PROJECTS, name: 'Projects', component: Projects },
  { path: ROUTES.SETTINGS, name: 'Settings', component: Settings },

  //Project details
  {
    path: ROUTES.PROJECT_PAGE,
    name: 'Project Track',
    component: ProjectTracking,
  },
  {
    path: ROUTES.PROJECT_DATA_MANAGEMENTS,

    name: 'Project DataManagements',
    component: ProjectDataManagement,
  },

  {
    path: ROUTES.PROJECT_GUIDELINES,
    name: 'Project Guidelines',
    component: ProjectGuidelines,
  },
  {
    path: ROUTES.PROJECT_ANALYSIS,
    name: 'Project Analysis',
    component: ProjectAnalysis,
  },
  {
    path: ROUTES.PROJECT_REVIEW,
    name: 'Project Review',
    component: ProjectReview,
  },
  {
    path: ROUTES.PROJECT_TASK,
    name: 'Project Task',
    component: ProjectTask,
  },
  { path: ROUTES.BILLING, name: 'Billing', component: Billing },

  //project creation
  {
    path: ROUTES.PROJECT_CREATE_STEP_0,
    name: 'Create Project Step-0',
    component: CreateProjectStep0,
  },
  {
    path: ROUTES.PROJECT_CREATE_STEP_1,
    name: 'Create Project Step-1',
    component: CreateProjectStep1,
  },

  {
    path: ROUTES.PROJECT_CREATE_STEP_2,
    name: 'Create Project Step-2',
    component: CreateProjectStep2,
  },
  {
    path: ROUTES.PROJECT_CREATE_STEP_3,
    name: 'Create Project Step-3',
    component: CreateProjectStep3,
  },
  {
    path: ROUTES.PROJECT_CREATE_STEP_4,
    name: 'Create Project Step-4',
    component: CreateProjectStep4,
  },
  {
    path: ROUTES.PROJECT_CREATE_STEP_5,
    name: 'Create Project Step-5',
    component: CreateProjectStep5,
  },
  { path: ROUTES.DOCS, name: 'Docs', component: Docs },
]

export default privateRoutes
