import React, { useState } from 'react'
import { RootState } from '../../../../store'
import { useSelector } from 'react-redux'
import { useFirebase } from '../../../../firebase'
import styles from './navbarRight.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Badge,
  IconButton,
  ButtonBase,
  Menu,
  MenuItem,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import Notifications from './Notifications'
import TrackingUsers from './TrackingUsers'
import ProjectSettings from './ProjectSettings'
import { ClipLoader } from 'react-spinners'
import HelpCard from '../../../HelpCard'
import HelpIcon from '@mui/icons-material/Help'
import Iconify from './Notifications/Iconify'
import { isBasicPlan } from '../../../../helpers'
import useProjectData from '@/hooks/useProjectData'

const NavRight = () => {
  const { customer, user } = useSelector((state: RootState) => state.auth)
  const location = useLocation()
  const path = location.pathname
  const navigate = useNavigate()
  const { api } = useFirebase()
  const { projectData } = useProjectData()

  const [helpCard, setHelpCard] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const showHelp = () => {
    setHelpCard(!helpCard)
  }

  return (
    <div className={styles.NavbarRight}>
      {/* Main NavRight */}
      <div className={styles.MainNavRight}>
        {/* Notification Menu */}
        <Notifications customerID={user?.uid} />
        {/* Help Button */}
        <IconButton
          onClick={() => setHelpCard(!helpCard)}
          className={styles.InfoSectionButton}
        >
          <Iconify
            icon="mdi:question-mark-circle"
            width={20}
            height={20}
            color={'#3943b7'}
          />
        </IconButton>
        {/* Profile Menu */}
      </div>
      {<HelpCard helpCard={helpCard} setHelpCard={setHelpCard} />}
    </div>
  )
}

export default NavRight
