import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDefaultMenu, setSettingsModal } from '../store/navigation';

function useSetDefaultMenu() {
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(setDefaultMenu());
      dispatch(setSettingsModal(0));
   }, [dispatch]);
}

export default useSetDefaultMenu;
