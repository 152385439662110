import React, { ReactNode, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar/index'
import styles from './layout.module.scss'
import { useGlobal } from '../../context/GlobalContext'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import AsyncLoader from './AsyncLoader'
import { useFirebase } from '../../firebase'
import { setProjects } from '../../store/projects'

import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { setCanceledSubsInfo, setSubsInfo } from '../../store/ui'
import './botpress.js'
import {
  BASIC_PLAN,
  ENTERPRISE_PLAN,
  PROFESSIONAL_PLAN,
  isBasicPlan,
  notify,
} from '../../helpers'
import FakeChatbotIcon from '../FakeChatBotIcon'
import { httpsCallable } from 'firebase/functions'
import useSetProjects from '@/hooks/useSetProjects'

const Layout = ({ children }: { children?: ReactNode }) => {
  // const isCollapse = useSelector((state: RootState) => state.ui.isCollapse);
  const { isCollapse } = useGlobal()
  const location = useLocation()
  const path = location.pathname
  const { loading } = useSelector((state: RootState) => state.async)
  const { isEditToolOpen } = useSelector((state: RootState) => state.ui)
  const { user, initialized, customer } = useSelector(
    (state: RootState) => state.auth
  )

  const { api, store, functions } = useFirebase()
  const dispatch = useDispatch()
  const [marginPx, setMarginPx] = useState('')
  const [paddingPx, setPaddingPx] = useState('')

  const [fakeChatbot, setFakeChatbot] = useState(false)
  const [subscriptions, setSubscriptions] = useState<any[]>([])

  useEffect(() => {
    if (path.includes('/docs')) {
      setMarginPx('0px')
      setPaddingPx('0px')
    }
    // else if (path.includes('/guidelines')) {
    //   setMarginPx('0px')
    //   setPaddingPx('0px 0px 0px 0px')
    // }
    else if (isCollapse) {
      setMarginPx('5rem')
      // setPaddingPx('0 20px 40px')
    } else {
      setMarginPx('14rem')
      // setPaddingPx('0 20px 40px')
    }
  }, [path, isCollapse])

  useSetProjects()

  useEffect(() => {
    if (user && customer) {
      let canceledSubs: any = []
      dispatch(setSubsInfo(null))
      dispatch(setCanceledSubsInfo(null))
      const unsub = onSnapshot(
        api?.listenToSubscriptions(),
        async (docs: any) => {
          docs.docs.forEach((doc: any) => {
            let subInfo = doc.data()
            subInfo['id'] = doc.id
            setSubscriptions((prev) => [...prev, subInfo])
          })
          dispatch(setCanceledSubsInfo(canceledSubs))
        }
      )
      return () => {
        unsub()
      }
    }
    // eslint-disable-next-line
  }, [user, initialized])

  useEffect(() => {
    // find all subscriptions with status active
    const activeSubs = subscriptions
      ?.filter((sub) => sub.status === 'active' && !sub.cancel_at_period_end)
      .sort((a, b) => b.created - a.created)
    // find all subscriptions with status active and cancel_at_period_end
    const canceledSubs = subscriptions
      ?.filter((sub) => sub.status === 'active' && sub.cancel_at_period_end)
      .sort((a, b) => b.created - a.created)
    dispatch(setCanceledSubsInfo(canceledSubs))
    if (activeSubs.length > 0) {
      dispatch(setSubsInfo(activeSubs[0]))
      if (activeSubs[0].items.at(-1)) {
        if (
          activeSubs[0].items.at(-1).price.product.name ===
          'Freemium Subscription'
        ) {
          api?.updateCustomerSubscriptionStatus('trialing')
        } else if (
          activeSubs[0].items.at(-1).price.product.name === 'Basic Subscription'
        ) {
          api?.updateCustomerSubscriptionStatus('active')
          api?.updateCustomerSubscriptionType('Basic')
        } else if (
          activeSubs[0].items.at(-1).price.product.name ===
          'Professional Subscription'
        ) {
          api?.updateCustomerSubscriptionStatus('active')
          api?.updateCustomerSubscriptionType('Professional')
        }
      }
    } else if (subscriptions?.length > 0) {
      api?.updateCustomerSubscriptionType('free')
    }
  }, [subscriptions])

  useEffect(() => {
    const element = document.querySelector('#bp-web-widget')
    if (element) {
      setFakeChatbot(false)
    } else {
      setFakeChatbot(true)
    }
  }, [document])

  useEffect(() => {
    const checkSignInWithInvitation = async () => {
      if (user) {
        const signUpWithInvitationRaw = localStorage.getItem(
          'signUpWithInvitation'
        )
        if (signUpWithInvitationRaw) {
          const dataOfInvitation = JSON.parse(signUpWithInvitationRaw)
          const { email, isUsed, projectName } = dataOfInvitation
          if (!isUsed) {
            if (email === user.email) {
              const addUserToProject = httpsCallable(
                functions,
                'addUserToProject'
              )
              notify('INFO', 'You will be joined the project, please wait')
              const responseAddUserToProject = await addUserToProject(
                dataOfInvitation
              )
              responseAddUserToProject.data &&
                notify(
                  'SUCCESS',
                  `You have successfully joined the project named ${projectName}. You can checkout the projects page.`
                )
              localStorage.setItem(
                'signUpWithInvitation',
                JSON.stringify({ ...dataOfInvitation, isUsed: true })
              )
            } else {
              localStorage.removeItem('signUpWithInvitation')
            }
          } else {
            localStorage.removeItem('signUpWithInvitation')
          }
        }
      }
    }
    checkSignInWithInvitation()
  }, [user])

  const elements = document.querySelectorAll('#bp-web-widget')
  if (elements.length > 1) {
    for (let i = 1; i < elements.length; i++) {
      elements[i].remove()
    }
  }

  useEffect(() => {
    if (window.location.pathname == '/') {
      if (
        customer?.subscriptionType === BASIC_PLAN ||
        customer?.subscriptionType === PROFESSIONAL_PLAN ||
        customer?.subscriptionType === ENTERPRISE_PLAN ||
        customer?.subscriptionStatus === 'trialing'
      ) {
        setFakeChatbot(false)
        const _window: any = window
        _window?.botpressWebChat?.init({
          botId: '0e7e64e4-226b-4ea8-84a4-a16953061dba',
          hostUrl: 'https://cdn.botpress.cloud/webchat/v0',
          messagingUrl: 'https://messaging.botpress.cloud',
          clientId: '0e7e64e4-226b-4ea8-84a4-a16953061dba',
          botName: 'Co-One Support',
          avatarUrl:
            'https://dashboard.co-one.co/static/media/logo.4dd253b0e646c72f62ac.png',
          showBotInfoPage: false,
          enableConversationDeletion: false,
          showConversationsButton: false,
          enableTranscriptDownload: false,
          enablePersistHistory: false,
          useSessionStorage: true,
          stylesheet: 'https://dashboard-q-a.vercel.app/botpress.css',
        })
        const botpressIframe: any = document.querySelector('#bp-web-widget')
        botpressIframe.style.bottom = '12px'
        botpressIframe.style.right = '16px'
        // botpressIframe.setAttribute(
        //   'style',
        //   'width:56px !important; height:56px !important'
        // )
        botpressIframe.style.zIndex = 999
      } else if (customer?.subscriptionType) {
        const elements = document.querySelectorAll('#bp-web-widget')
        for (let i = 0; i < elements.length; i++) {
          elements[i].remove()
        }
        setFakeChatbot(true)
      }
    } else {
      const elements = document.querySelectorAll('#bp-web-widget')
      if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].remove()
        }
      }
      setFakeChatbot(false)
    }
  }, [
    customer?.subscriptionType,
    customer?.subscriptionStatus,
    window.location.pathname,
  ])

  return (
    <>
      {loading !== 0 && <AsyncLoader />}
      {/* {!path.includes('/settings') && !path.includes('/docs') && !path.includes('/guidelines')  && !isEditToolOpen  && <Sidebar />} */}
      {
        // !path.includes('/settings') &&
        !path.includes('/docs') && <Sidebar />
      }
      <Navbar />
      <div
        className={styles.Container}
        style={{
          marginLeft: marginPx,
          marginRight: '1.5rem',
          padding: paddingPx,
        }}
      >
        {children}
      </div>
      {/* {fakeChatbot && <FakeChatbotIcon />} */}
    </>
  )
}

export default Layout
