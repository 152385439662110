import { createSlice } from '@reduxjs/toolkit'

export interface UIState {
  isCollapse: boolean
  isStaging: boolean
  activeSubscriptionInfo: any
  canceledSubscriptionInfo: any
  isUploadOnProgressProject: any
  isEditToolOpen: any
}

const initialState: UIState = {
  isCollapse: false,
  isStaging: true,
  activeSubscriptionInfo: null,
  canceledSubscriptionInfo: null,
  isUploadOnProgressProject: {},
  isEditToolOpen: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleCollapseMenu: state => {
      state.isCollapse = !state.isCollapse
    },
    setSubsInfo: (state, action) => {
      state.activeSubscriptionInfo = action.payload
    },
    setCanceledSubsInfo: (state, action) => {
      state.canceledSubscriptionInfo = action.payload
    },
    setIsStaging: (state, action) => {
      state.isStaging = action.payload
    },
    setIsUploadOnProgressProject: (state, action) => {
      state.isUploadOnProgressProject[action.payload] = true
    },
    setIsEditToolOpen: (state, action) => {
      state.isEditToolOpen = action.payload
    }
  },
})

export const {
  toggleCollapseMenu,
  setSubsInfo,
  setCanceledSubsInfo,
  setIsStaging,
  setIsUploadOnProgressProject,
  setIsEditToolOpen
} = uiSlice.actions

export default uiSlice.reducer
