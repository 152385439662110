import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFirebase } from "../firebase";
import { RootState } from "../store";
import useProjectData from "./useProjectData";
import { asyncActionFinish, asyncActionStart } from "../store/async";

const useProjectRole = () => {
  const [userRole, setUserRole] = useState(null);

  const { projectData, projectName } = useProjectData();
  const { user } = useSelector((state: RootState) => state.auth);
  const { api } = useFirebase();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getRole() {
      try {
        dispatch(asyncActionStart());
        if (projectData) {
          if (user) {
            const role = await api?.getDocByPath(
              `/Projects/${projectData?.projectID}/addedCustomers/${user?.uid}`
            );
            setUserRole(role.roleRef);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(asyncActionFinish());
      }
    }
    getRole();
    // eslint-disable-next-line
  }, [projectName]);

  return { userRole };
};

export default useProjectRole;
