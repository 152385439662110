import React from 'react'
import styles from './navbar.module.scss'
import { useLocation } from 'react-router-dom'
import NavRight from './NavRight'
import NavLeft from './NavLeft/index'
// import { useSelector } from "react-redux";
// import { RootState } from "../../../store";
import { useGlobal } from '../../../context/GlobalContext'

const Navbar = () => {
  const location = useLocation()
  const path = location.pathname
  // const { isCollapse } = useSelector((state: RootState) => state.ui);
  const { isCollapse } = useGlobal()

  const customStyle = () => {
    const pathname = location.pathname
    if (pathname === '/projects') {
      if (isCollapse) {
        return {
          paddingTop: '20px',
          paddingBottom: '25px',
          width: 'calc(100% - 80px)',
          marginLeft: '80px',
        }
      } else {
        return { paddingTop: '20px', paddingBottom: '30px' }
      }
    } else if (
      pathname.includes('/settings') ||
      pathname.includes('/docs')
      // pathname.includes('/guidelines')
    ) {
      return { marginLeft: 40, width: '97%' }
    } else if (isCollapse) {
      return {
        width: 'calc(100% - 80px)',
        marginLeft: '70px',
        paddingTop: '20px',
        paddingBottom: '30px',
      }
    } else return { paddingTop: '20px', paddingBottom: '12px' }
  }

  const isProjectNavigationShown = () => {
    if (
      path.includes('/projects/') &&
      path !== '/projects/' &&
      !path.includes('/projects/create')
      // !path.includes('/guidelines')
    ) {
      return true
    }
    return false
  }

  const isSettingsNavigationShown = () => {
    if (path.includes('/setting')) {
      return true
    }
    return false
  }
  const isToolFullScreen = () => {
    // if (path.includes('/guidelines')) {
    //   return true
    // }
    return false
  }

  const isDocsNavigationShown = () => {
    if (path.includes('/docs')) {
      return true
    }
    return false
  }

  const isWelcomeMessageShown = () => {
    if (path === '/' || path === '/projects' || path === '/projects/') {
      return true
    }
    return false
  }

  const getProjectName = () => {
    const splittedPath = path.split('/')
    if (splittedPath.length >= 3) {
      const projectName = splittedPath[2]
      return decodeURIComponent(projectName)
    }
  }

  return (
    <header className={styles.Header} style={customStyle()}>
      <div className={styles.navBar}>
        <NavLeft
          isProjectNavigationShown={isProjectNavigationShown}
          isWelcomeMessageShown={isWelcomeMessageShown}
          isSettingsNavigationShown={isSettingsNavigationShown}
          isDocsNavigationShown={isDocsNavigationShown}
          getProjectName={getProjectName}
          isToolFullScreen={isToolFullScreen}
        />

        <NavRight />
      </div>
      {/* {path.includes('/guidelines') && (
        <div className={styles.VerticleLine}></div>
      )} */}
    </header>
  )
}

export default Navbar
