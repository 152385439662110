import React, { Suspense } from "react";
import Loader from "../../../components/Layout/Loader";

const PublicRouteElement = ({ route }: any) => {
  return (
    <Suspense fallback={<Loader />}>
      <route.component />
    </Suspense>
  );
};

export default PublicRouteElement;
