import React from 'react'
import './AsyncLoader.scss'
import { PulseLoader } from 'react-spinners'

const AsyncLoader = () => {
  return (
    <>
      <div className="overlay"></div>
      <div className="loaderBox">
        <PulseLoader
          color="#147ad6"
          size={25}
          margin={15}
          speedMultiplier={0.6}
        />
      </div>
    </>
  )
}

export default AsyncLoader
