import React, { useEffect, useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import styles from './CardItem.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import CardList from '../CardList'
import { ButtonBase, Grid, InputLabel } from '@mui/material'
import Swal from 'sweetalert2'
import AddIcon from '@mui/icons-material/Add'
import { asyncActionFinish, asyncActionStart } from '../../../store/async'
import { useFirebase } from '../../../firebase'
import { notify } from '../../../helpers'
import { RootState } from '../../../store'
import { httpsCallable } from 'firebase/functions'
import { Box, Button } from '@mui/joy'
import './styles.css'

const options = {
  style: {
    base: {
      fontSize: '1.2rem',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
  },
}

const CardItem = ({ paymentDetails, setActiveStep, planName, annual }: any) => {
  const stripe: any = useStripe()
  const elements = useElements()
  const { functions, api } = useFirebase()
  const dispatch = useDispatch()
  const { subscriptionType } = useSelector(
    (state: RootState) => state.auth.customer
  )

  const [plans, setPlans] = useState<any>()
  const [cardSuccess, setCardSuccess] = useState(true)
  const [submitClicked, setSubmitClicked] = useState(false)
  // eslint-disable-next-line

  const updatePaymentMethod: any = async (paymentMethod: any, type: any) => {
    const updatePM = httpsCallable(functions, 'updatePaymentMethod')
    if (paymentMethod) {
      try {
        dispatch(asyncActionStart())
        await updatePM({
          operationType: type,
          payment_method_ID: paymentMethod.id,
          billing_details: paymentDetails,
        })
      } catch (error) {
        console.log(error)
      } finally {
        dispatch(asyncActionFinish())
        switch (type) {
          case 'update':
            notify('SUCCESS', 'Card has been updated successfully')
            break
          default:
            break
        }
      }
    }
  }

  const getCheckoutSession: any = async (
    planID: any,
    operationType: any,
    planName: any
  ) => {
    const stripeSub = httpsCallable(functions, 'handleStripeSubscription')
    try {
      dispatch(asyncActionStart())
      // eslint-disable-next-line
      const { data } = await stripeSub({
        subscriptionOperationType: operationType,
        planPriceID: planID,
        yearlyPayment: annual,
      })
      if (data) {
        notify('SUCCESS', 'Payment successful! You are now subscribed.')
        api?.updateCustomerSubscriptionType(planName)
        setActiveStep((prev: any) => prev + 1)
      }
    } catch (error) {
      const _error: any = error
      console.log(_error.message)
      notify('FAIL', 'Something went wrong, please try again.')
    } finally {
      dispatch(asyncActionFinish())
    }
  }

  const handlePlan = async (planName: any) => {
    let operationType
    switch (subscriptionType) {
      case 'free':
        operationType = 'create'
        break
      default:
        operationType = 'update'
        break
    }

    switch (planName) {
      case 'Basic':
        const basic =
          process.env.REACT_APP_PROJECT_ID === 'co-one-app-dev-44eaa'
            ? 'price_1NAuZmF573ujgcriJqPgI95K'
            : 'price_1NAufqF573ujgcripnrmIOBw'
        getCheckoutSession(basic, operationType, planName)
        break
      case 'Professional':
        if (annual) {
          const professional =
            process.env.REACT_APP_PROJECT_ID === 'co-one-app-dev-44eaa'
              ? 'price_1NDXPuF573ujgcriXYCF9qlQ'
              : 'price_1NUo61F573ujgcrieXGwVxYA'
          getCheckoutSession(professional, operationType, planName)
        } else {
          const professional =
            process.env.REACT_APP_PROJECT_ID === 'co-one-app-dev-44eaa'
              ? 'price_1NAubrF573ujgcrii8S7SFyT' // price_1NDXPuF573ujgcriXYCF9qlQ one time yearly
              : 'price_1NAugvF573ujgcridtBR5y5z' // yearly price_1NAugkF573ujgcri7UhYqkdt, dev price_1NAuayF573ujgcrieY7a3C32
          getCheckoutSession(professional, operationType, planName)
        }
        break
      // case 'ScaleUp':
      //   const enterprise = plans['ScaleUp Subscription']
      //   getCheckoutSession(enterprise, operationType)
      //   break
      default:
        break
    }
  }

  const createIntent = async (paymentMethod: any) => {
    if (paymentMethod === undefined) {
      notify(
        'FAIL',
        'Please make sure you enter the card information completely!'
      )
      return
    }
    try {
      dispatch(asyncActionStart())
      const stripeIntent = httpsCallable(functions, 'createSetupIntent')
      const { data }: any = await stripeIntent({
        payment_method_ID: paymentMethod.id,
      })
      console.log(data)
      if (data.next_action) {
        const confirmPayment = await stripe.confirmCardSetup(data.client_secret)
        const { setupIntent } = confirmPayment
        if (setupIntent !== undefined) {
          notify('SUCCESS', 'Card has been added successfully')
          updatePaymentMethod(paymentMethod, 'update')
          handlePlan(planName)
        }
        if (
          confirmPayment.error.code === 'setup_intent_authentication_failure'
        ) {
          notify(
            'FAIL',
            'Authentication failed, there was an issue with your card, please enter another payment method'
          )
        }
        if (confirmPayment.error.code === 'card_declined') {
          notify(
            'FAIL',
            'Card has been declined, please enter another payment method'
          )
        }
      } else {
        updatePaymentMethod(paymentMethod, 'update')
        handlePlan(planName)
      }
    } catch (error) {
      console.log(error)
      setCardSuccess(false)
    } finally {
      dispatch(asyncActionFinish())
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setSubmitClicked(true)
    if (!stripe || !elements) return

    try {
      asyncActionStart()
      const payload: any = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: paymentDetails,
      })
      await createIntent(payload.paymentMethod)
    } catch (error) {
      console.log(error)
      notify('FAIL', 'Make sure you enter the card information completely!')
    } finally {
      asyncActionFinish()
      setSubmitClicked(false)
    }
  }

  useEffect(() => {
    const getPlan: any = {}
    api?.getAllDocsByPathAndQuery(`Plans`, 'name', 'asc').then((res: any) => {
      res.map((doc: any) => {
        getPlan[doc['name']] = ''
        api?.getAllDocsByPath(`Plans/${doc.id}/prices`).then((res2: any) => {
          res2.map((doc2: any) => {
            getPlan[doc['name']] = doc2.id
            setPlans(getPlan)
          })
        })
      })
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!cardSuccess) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Payment failed try again',
        showConfirmButton: false,
        timer: 1500,
      })
      setCardSuccess(true)
    }
  }, [cardSuccess])

  return (
    <Box>
      <form onSubmit={handleSubmit} id="form" className={styles.FormContainer}>
        <Grid container spacing={1.5}>
          <Grid item md={12} xs={12}>
            <InputLabel className={styles.MuiFormLabel}>
              Card Number *
            </InputLabel>
            <CardNumberElement options={options} />
          </Grid>

          <Grid item md={6} xs={6}>
            <InputLabel className={styles.MuiFormLabel}>
              Expiry Date *
            </InputLabel>

            <CardExpiryElement options={options} />
          </Grid>
          <Grid item md={6} xs={6}>
            <InputLabel className={styles.MuiFormLabel}>Cvv *</InputLabel>

            <CardCvcElement options={options} />
          </Grid>
        </Grid>

        <div className={styles.ButtonWrapper}>
          <Button
            variant="outlined"
            sx={{
              width: '30%',

              color: 'var(--schemePurple)',
              borderColor: 'var(--schemePurple)',
              '&:hover': {
                borderColor: 'var(--schemePurple)',
                color: '#fff',
                backgroundColor: 'var(--schemePurple)',
              },
            }}
            onClick={() => setActiveStep((prev: any) => prev - 1)}
          >
            Prev
          </Button>
          <Button
            type="submit"
            variant="solid"
            sx={{
              width: '30%',
              color: 'white',
              backgroundColor: 'var(--schemePurple)',
              '&:hover': {
                backgroundColor: 'var(--schemePurple)',
              },
              ml: 'auto',
            }}
            disabled={!stripe || submitClicked}
          >
            Pay
          </Button>
        </div>
      </form>
    </Box>
  )
}

export default CardItem
