import { createSlice } from '@reduxjs/toolkit'

export interface AuthState {
  user: any
  customer: any
  initialized: boolean
  loginError: any
  logoutError: any
}

const initialState: AuthState = {
  user: null,
  customer: null,
  initialized: false,
  loginError: null,
  logoutError: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload
    },
    logout: () => initialState,
    setInitalizeApp: state => {
      state.initialized = true
    },
    setCustomer: (state, action) => {
      // except stripeId, stripeLink
      delete action?.payload?.stripeId
      delete action?.payload?.stripeLink
      state.customer = action.payload
    },
    loginError: (state, action) => {
      state.loginError = action.payload
    },
    logoutError: (state, action) => {
      state.logoutError = action.payload
    },
    setSessionID: (state, action) => {
      state.customer = {
        ...state.customer,
        sessionID: action.payload,
      }
    },
  },
})

export const {
  login,
  logout,
  setInitalizeApp,
  setCustomer,
  loginError,
  logoutError,
  setSessionID,
} = authSlice.actions

export default authSlice.reducer
