import { createSlice } from '@reduxjs/toolkit'

export interface GuidelinesState {
  modalImages: any
}

const initialState: GuidelinesState = {
  modalImages: [],
}

export const guidelinesSlice = createSlice({
  name: 'guidelines',
  initialState,
  reducers: {
    addModalImage: (state, action) => {
      state.modalImages.push(action.payload)
    },
    removeModalImage: (state, action) => {
      state.modalImages = state.modalImages.filter(
        (image: any) => image.name !== action.payload.name
      )
    },
    clearModalImages: (state) => {
      state.modalImages = []
    },
    increaseImageProgress: (state, action) => {
      let index = state.modalImages.findIndex(
        (image: any) => image.name === action.payload.name
      )
      let images = [...state.modalImages]
      images[index].progress = action.payload.progress
      state.modalImages = [...images]
    },
  },
})

export const {
  addModalImage,
  removeModalImage,
  clearModalImages,
  increaseImageProgress,
} = guidelinesSlice.actions

export default guidelinesSlice.reducer
