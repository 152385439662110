import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../store'

const useProjectData = () => {
  const { projectName } = useParams()
  const { projects } = useSelector((state: RootState) => state.projects)
  const projectData = projects?.find(
    (proj: any) => proj?.projectName === projectName
  )
  const projectType = projectData?.projectRequirments?.projectType
  const projectAnnoType = projectData?.projectRequirments?.annotationType
  return { projectData, projectType, projects, projectName, projectAnnoType }
}

export default useProjectData
