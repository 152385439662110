import ReactApexChart from 'react-apexcharts';
// @mui
import React from 'react';
import { Card, CardHeader } from '@mui/material';
// components

import useChart from './index';
import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------

const StyledChartWrapper = styled('div')(({ theme }) => ({
   position: 'absolute',
   '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
      overflow: 'visible'
   },
   '& .apexcharts-legend': {
      // height: LEGEND_HEIGHT,
      alignContent: 'center',
      position: 'unset !important',
      top: `195px !important`
   }
}));
export default function PieChart({
   title,
   subheader,
   chartColors,
   chartData,
   width,
   chartType,
   showLegend = false,
   showDataLabel = false,
   ...other
}:any) {
   const chartLabels = chartData.map((i:any) => i.label);

   const chartSeries = chartData.map((i:any) => i.value);
   const chartOptions = useChart({
      colors: chartColors,
      labels: chartLabels,
      stroke: { colors: ['transparent'] },
      legend: {
         floating: true,
         horizontalAlign: 'center',
         show: showLegend,
         onItemHover: {
            highlightDataSeries: true
         }
      },
      dataLabels: { enabled: showDataLabel, dropShadow: { enabled: false } },
      // animations: {
      //    enabled: true,
      //    easing: 'easeinout',
      //    speed: 800,
      //    animateGradually: {
      //       enabled: true,
      //       delay: 150
      //    },
      //    dynamicAnimation: {
      //       enabled: true,
      //       speed: 350
      //    }
      // },
      // annotations: {
      //    yaxis: [
      //       {
      //          y: 8800,
      //          borderColor: '#00E396',
      //          label: {
      //             borderColor: '#00E396',
      //             style: {
      //                color: '#fff',
      //                background: '#00E396'
      //             },
      //             text: 'Y-axis annotation on 8800'
      //          }
      //       }
      //    ]
      // },

      tooltip: {
         fillSeriesColor: false,
         y: {
            formatter: (seriesName: any) => {
               const formattedNumber = new Intl.NumberFormat('en-US', {
                 style: 'decimal',
                 minimumFractionDigits: 0,
                 maximumFractionDigits: 2,
               }).format(seriesName);
               return formattedNumber;
             },
            title: {
               formatter: (seriesName:number) => `${seriesName}`
            }
         }
      },
      plotOptions: {
         pie: { donut: { labels: { show: false } } }
      }
   });

   return (
      <Card
         sx={{
            textAlign: 'center',
            height: 'auto',
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            boxShadow: 'none'
         }}
      >
         <CardHeader />

         <StyledChartWrapper dir="ltr">
            <ReactApexChart type={chartType} series={chartSeries} options={chartOptions} width={width} />
         </StyledChartWrapper>
      </Card>
   );
}